import React, { useState } from "react";
import {
  Container,
  Button,
  Breadcrumb,
  Form,
  Row,
  Col,
  Toast,
  ProgressBar,
} from "react-bootstrap";
import { Navigate, Link } from "react-router-dom";
import Loader from "../../ui/Layout/Loader";
import usePermissions from "../../hooks/usePermissions"; 
import "react-toastify/dist/ReactToastify.css";
import { postMultipartData } from "../../api/apiService";

const PVPUploadPageComponent = () => {
  const { permissions, permissionsLoaded } = usePermissions();
  const [file, setFile] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState(""); // 'success' or 'error'
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploading, setUploading] = useState(false);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileUpload = async (e) => {
    e.preventDefault();
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      try {
        setUploading(true);
        setUploadProgress(0);
        simulateProgress();

        const response = await postMultipartData("/aws/uploadFile", formData);
        console.log("response", response);

        if (response.status === 200) {
          setToastMessage(response.data.message);
          setToastType("success");
        } else {
          setToastMessage("Oops! Something went wrong.");
          setToastType("error");
        }
      } catch (error) {
        setToastMessage("Error uploading file: " + error.message);
        setToastType("error");
        console.error("Error uploading file:", error);
      } finally {
        setShowToast(true);
        setUploading(false);
      }
    }
  };

  const simulateProgress = () => {
    const duration = 50000; // 20 seconds
    const intervalTime = 200; // Update every 200ms
    const increment = 100 / (duration / intervalTime);

    const interval = setInterval(() => {
      setUploadProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prevProgress + increment;
      });
    }, intervalTime);
  };

  const handleCloseToast = () => setShowToast(false);

  if (!permissionsLoaded) {
    return <Loader />;
  }

  return (
    <div>
      {permissions?.includes("pvp_manual_review_list") ? (
        <Container className="border border-light rounded px-5 mt-4">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link className="text-dark link" to="/pvp">
                PVP
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>PVP Form Upload</Breadcrumb.Item>
          </Breadcrumb>
          {uploading && (
            <ProgressBar
              animated
              now={uploadProgress}
              label={`${Math.round(uploadProgress)}%`}
              className="mb-3"
            />
          )}
          <Row className="justify-content-center">
            <Col md={6}>
              <Form onSubmit={handleFileUpload} className="mb-4">
                <Form.Group controlId="formFile">
                  <Form.Label>Upload File</Form.Label>
                  <Form.Control type="file" onChange={handleFileChange} />
                </Form.Group>
                <Button variant="primary" type="submit" className="mt-3">
                  Upload
                </Button>
              </Form>
            </Col>
          </Row>
          <div
            className="position-fixed top-0 end-0 p-3"
            style={{ zIndex: 9999 }}
          >
            <Toast
              show={showToast}
              onClose={handleCloseToast}
              className={
                toastType === "success"
                  ? "bg-success text-white"
                  : "bg-danger text-white"
              }
              delay={3000}
              autohide
            >
              <Toast.Header closeButton={true}>
                <strong className="me-auto">
                  {toastType === "success" ? "Success" : "Error"}
                </strong>
              </Toast.Header>
              <Toast.Body>{toastMessage}</Toast.Body>
            </Toast>
          </div>
        </Container>
      ) : (
        <Navigate to="/401" replace />
      )}
    </div>
  );
};

export default PVPUploadPageComponent;
