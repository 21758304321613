import React, { useState } from "react";
import {
  Table,
  Button,
  Form,
  InputGroup,
  Dropdown,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { FaPlus, FaCheck } from "react-icons/fa";
import { fetchData } from "../../api/apiService";
import { IoIosLink } from "react-icons/io";
import usePermissions from "../../hooks/usePermissions";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

const formatICDCode = (code) => {
  if (!code) return "";
  return code.length > 3 ? `${code.slice(0, 3)}.${code.slice(3)}` : code;
};

export const CoderReviewClaimsRecapture = ({ reviewData }) => (
  <>
    <h5>Claims Recapture Codes</h5>
    <Table className="coder-review table-bordered tbl-align-middle">
      <thead>
        <tr>
          <th></th>
          <th>ICD-10 Code</th>
          <th>Description</th>
          <th>Date of Service</th>
        </tr>
      </thead>
      <tbody>
        {reviewData.claimsRecaptureCodes.length > 0 ? (
          reviewData.claimsRecaptureCodes.map((code, index) => (
            <tr key={index} className="icd-auto-tr">
              <td>
                <Button className="primary-btn icd-btn">A</Button>
              </td>
              <td>{formatICDCode(code.code)}</td>
              <td>{code.description}</td>
              <td>{code.dateOfService}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="4" className="text-center">
              There is no data to display.
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  </>
);

export const CoderReviewSuspectDiagnosis = ({
  reviewData,
  selectedRows,
  handleRowSelection,
}) => {
  const toggleSelection = (index) => {
    handleRowSelection(index, "suspectDiagnosisCodes");
  };

  const { permissions } = usePermissions();

  const sortedSuspectDiagnosisCodes = reviewData.suspectDiagnosisCodes.sort(
    (a, b) => {
      if (a.mvpCategory === "Auto PVP" && b.mvpCategory !== "Auto PVP") {
        return -1;
      } else if (a.mvpCategory !== "Auto PVP" && b.mvpCategory === "Auto PVP") {
        return 1;
      } else {
        return 0;
      }
    }
  );

  return (
    <>
      <h5>Suspect Diagnosis Codes</h5>
      <Table className="coder-review table-bordered tbl-align-middle">
        <thead>
          <tr>
            <th style={{ width: "2%" }}>
              <span style={{ visibility: "hidden" }}>S.No</span>
            </th>
            <th style={{ width: "9%" }}>ICD-10 Code</th>
            <th style={{ width: "20%" }}>Diagnosis Description</th>
            <th style={{ width: "10%" }}>Source Date</th>
            <th style={{ width: "23%" }}>Excerpt</th>
            <th style={{ width: "11%" }}>Evidence Type</th>
            <th style={{ width: "11%" }}>Document Type</th>
            <th style={{ width: "11%" }}>Encounter Type</th>
            <th style={{ width: "3%" }}></th>
          </tr>
        </thead>
        <tbody>
          {sortedSuspectDiagnosisCodes.length > 0 ? (
            sortedSuspectDiagnosisCodes.map((code, index) => {
              const isAutoPVP = code.mvpCategory === "Auto PVP";
              const isSelected =
                selectedRows[index] || code.mvpCategory === "Coders bucket";

              let rowClass = "";
              let buttonClass = "";
              let buttonLabel = "";

              if (isAutoPVP) {
                rowClass = "icd-auto-tr";
                buttonClass = "primary-btn icd-btn";
                buttonLabel = "A";
              } else if (isSelected) {
                rowClass = "icd-selected-tr";
                buttonClass = "primary-btn icd-btn-selected";
                buttonLabel = <FaCheck className="coder-tick" />;
              } else {
                rowClass = "icd-not-selected-tr";
                buttonClass = "primary-btn icd-btn-not-selected";
                buttonLabel = "";
              }

              return (
                <tr key={index} className={rowClass}>
                  <td>
                    <div>
                      {permissions?.includes("pvp_coder_review_edit") ? (
                        <Button
                          className={buttonClass}
                          onClick={() => toggleSelection(index)}
                        >
                          {buttonLabel}
                        </Button>
                      ) : (
                        <Button className={buttonClass}>{buttonLabel}</Button>
                      )}
                    </div>
                  </td>
                  <td>{formatICDCode(code.code)}</td>
                  <td>{code.description}</td>
                  <td>{code.dateOfService}</td>
                  <td>{code.sourceExcerpt}</td>
                  <td>{code.sourceEvidenceType}</td>
                  <td>{code.sourceDocumentType}</td>
                  <td>{code.sourceEncounterType}</td>
                  <td className="text-center">
                    {code.sdCompendiumUrl && (
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip-${code.sdCompendiumUrl}`}>
                            Open Compendium
                          </Tooltip>
                        }
                      >
                        <Button
                          className="pvp-form-edit-button bg-white text-black"
                          style={{
                            border: "1px solid #C7C6C6",
                            borderRadius: "3px",
                          }}
                          variant="link"
                          onClick={() =>
                            window.open(code.sdCompendiumUrl, "_blank")
                          }
                        >
                          <IoIosLink className="text-15" />
                        </Button>
                      </OverlayTrigger>
                    )}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="8" className="text-center">
                There is no data to display.
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};

const getAllIcdCodes = (reviewData) => {
  const autoPvpClaimsRecaptureCodes = reviewData.claimsRecaptureCodes
    .filter((code) => code.mvpCategory === "Auto PVP")
    .map((code) => code.code);

  const autoPvpSuspectDiagnosisCodes = reviewData.suspectDiagnosisCodes
    .filter((code) => code.mvpCategory === "Auto PVP")
    .map((code) => code.code);

  const addedICDCodes = reviewData.addedICDCodes.map((code) => code.code);

  return [
    ...autoPvpClaimsRecaptureCodes,
    ...autoPvpSuspectDiagnosisCodes,
    ...addedICDCodes,
  ].join(",");
};

export const CoderReviewAddedICDCodes = ({
  reviewData,
  newICDCode,
  handleInputChange,
  handleAddRow,
  documentTypes,
  encounterTypes,
  evidenceTypes,
  selectedRows,
  handleRowSelection,
}) => {
  const [suggestions, setSuggestions] = useState({});
  const [showSuggestions, setShowSuggestions] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const { permissions } = usePermissions();

  const handleICD10Change = (e, index) => {
    let value = e.target.value.replace(".", ""); // Remove any existing dot to handle formatting correctly

    // Store the raw value without formatting in the state
    handleInputChange({ target: { value: value } }, "code", index);

    // Format the value to add a dot after 3 characters, but only for display purposes
    if (value.length > 3) {
      value = `${value.slice(0, 3)}.${value.slice(3)}`;
    }

    // Update the display value in the input field
    e.target.value = value;

    if (value.length >= 2) {
      fetchICDCodes(value, index); // Function to fetch ICD codes based on input
    } else {
      setShowSuggestions((prev) => ({
        ...prev,
        [index]: false,
      }));
    }
  };

  const formatICDCode = (code) => {
    if (!code) return "";
    return code.length > 3 ? `${code.slice(0, 3)}.${code.slice(3)}` : code;
  };

  const fetchICDCodes = async (value, index) => {
    const cleanValue = value.replace(/\./g, "");

    const exceptIcdCodes = getAllIcdCodes(reviewData);

    try {
      const response = await fetchData(
        `/pvp-icd/list?search=${cleanValue}&exceptIcdCodes=${exceptIcdCodes}`
      );
      const icdCodes = response.icdCodes || [];

      setSuggestions((prev) => ({
        ...prev,
        [index]:
          icdCodes.length > 0
            ? icdCodes
            : [{ code: null, description: "No result found" }],
      }));
      setShowSuggestions((prev) => ({
        ...prev,
        [index]: true,
      }));
    } catch (error) {
      console.error("Error fetching ICD codes:", error);
      setShowSuggestions((prev) => ({
        ...prev,
        [index]: false,
      }));
    }
  };

  const handleSuggestionClick = (code, index) => {
    if (code.code) {
      handleInputChange({ target: { value: code.code } }, "code", index);
      handleInputChange(
        { target: { value: code.description } },
        "description",
        index
      );

      setShowSuggestions((prev) => ({
        ...prev,
        [index]: false,
      }));
    }
  };

  const handleAddNewRow = () => {
    const newIndex = reviewData.addedICDCodes.length;

    handleAddRow();

    handleRowSelection(newIndex, "addedICDCodes");
  };

  const isRowFilled = (code) => {
    const requiredFields = [
      "code",
      "description",
      "excerpt",
      "sourceDate",
      "evidenceType",
      "documentType",
      "encounterType",
    ];

    return requiredFields.every((field) => {
      const value = code[field];
      // Ensure trim is only called on strings
      if (typeof value === "string") {
        return value.trim() !== "";
      }
      // Handle other types (e.g., null, undefined, or non-string values)
      return value !== null && value !== undefined;
    });
  };

  const handleRowSelectionWithValidation = (index, section) => {
    const selectedRow = reviewData[section] ? reviewData[section][index] : null;
    if (selectedRow) {
      // Clear previous validation errors first
      setValidationErrors((prev) => {
        const updatedErrors = { ...prev };
        delete updatedErrors[index];
        return updatedErrors;
      });
      // Validate the row
      if (!isRowFilled(selectedRow)) {
        const fieldsWithErrors = {};

        Object.keys(selectedRow).forEach((field) => {
          const value = selectedRow[field];
          if (
            (typeof value === "string" && value.trim() === "") ||
            value === null ||
            value === undefined
          ) {
            fieldsWithErrors[field] = true;
          }
        });

        setValidationErrors((prev) => ({
          ...prev,
          [index]: fieldsWithErrors,
        }));

        toast.error("All fields must be filled before selecting.", {
          position: "top-right",
        });

        return; // Stop further execution if validation fails
      }

      // Proceed with row selection if validation passes
      handleRowSelection(index, section);
    }
  };

  return (
    <div>
      {permissions?.includes("pvp_coder_review_edit") ? (
        <div>
          <div className="d-flex justify-content-between align-items-center">
            <h5>Added Suspect Diagnosis Codes</h5>
            <Button
              className="primary-btn me-0 btn btn-primary mb-2 font-size-14"
              onClick={handleAddNewRow}
            >
              <FaPlus className="pe-2 text-20" />
              Add Suspect ICD-10 Code
            </Button>
          </div>
          <Table className="coder-review table-bordered tbl-align-middle">
            <thead>
              <tr>
                <th style={{ width: "2%" }}>
                  <span style={{ visibility: "hidden" }}>S.No</span>
                </th>
                <th style={{ width: "9%" }}>ICD-10 Code</th>
                <th style={{ width: "20%" }}>Diagnosis Description</th>
                <th style={{ width: "10%" }}>Source Date</th>
                <th style={{ width: "23%" }}>Excerpt</th>
                <th style={{ width: "12%" }}>Evidence Type</th>
                <th style={{ width: "12%" }}>Document Type</th>
                <th style={{ width: "12%" }}>Encounter Type</th>
              </tr>
            </thead>
            <tbody>
              {reviewData.addedICDCodes.length > 0 ? (
                reviewData.addedICDCodes.map((code, index) => (
                  <tr
                    key={index}
                    className={
                      selectedRows[index]
                        ? "icd-selected-tr"
                        : "icd-not-selected-tr"
                    }
                  >
                    <td>
                      <Button
                        className={
                          selectedRows[index] && isRowFilled(code)
                            ? "primary-btn icd-btn-selected switch-icd-selection"
                            : "primary-btn icd-btn-not-selected switch-icd-selection"
                        }
                        onClick={() =>
                          handleRowSelectionWithValidation(
                            index,
                            "addedICDCodes"
                          )
                        }
                      >
                        {selectedRows[index] && (
                          <FaCheck className="coder-tick" />
                        )}
                      </Button>
                    </td>
                    <td>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          placeholder="ICD-10 CODE"
                          value={formatICDCode(code.code)}
                          onChange={(e) => handleICD10Change(e, index)}
                          className={
                            validationErrors[index]?.code ? "border-danger" : ""
                          }
                        />
                        {showSuggestions[index] && (
                          <Dropdown.Menu show>
                            {suggestions[index]?.map((suggestion, idx) =>
                              suggestion.code ? (
                                <Dropdown.Item
                                  key={idx}
                                  onClick={() =>
                                    handleSuggestionClick(suggestion, index)
                                  }
                                >
                                  {suggestion.code} - {suggestion.description}
                                </Dropdown.Item>
                              ) : (
                                <Dropdown.Item
                                  key={idx}
                                  disabled
                                  className="text-muted"
                                >
                                  {suggestion.description}
                                </Dropdown.Item>
                              )
                            )}
                          </Dropdown.Menu>
                        )}
                      </InputGroup>
                    </td>
                    <td>
                      <Form.Control
                        as="textarea"
                        placeholder="Description of selected ICD-10 Code"
                        readOnly
                        value={code.description}
                        onChange={(e) =>
                          handleInputChange(e, "description", index)
                        }
                        className={
                          validationErrors[index]?.description
                            ? "border-danger"
                            : ""
                        }
                        style={{
                          resize: "both",
                          height: "80px",
                          padding: "10px",
                          borderRadius: "5px",
                          borderColor: "#ced4da",
                          backgroundColor: "#dddddd",
                          fontSize: "14px",
                          lineHeight: "1.5",
                        }}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="date"
                        placeholder="MM/DD/YYYY"
                        value={code.sourceDate}
                        onChange={(e) =>
                          handleInputChange(e, "sourceDate", index)
                        }
                        className={
                          validationErrors[index]?.sourceDate
                            ? "border-danger"
                            : ""
                        }
                        max={new Date().toISOString().split("T")[0]}
                      />
                    </td>
                    <td>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Excerpt"
                        value={code.excerpt}
                        onChange={(e) => handleInputChange(e, "excerpt", index)}
                        maxLength={400}
                        className={
                          validationErrors[index]?.excerpt
                            ? "border-danger"
                            : ""
                        }
                        style={{
                          resize: "vertical", // Allows the user to resize the textarea vertically
                          height: "100px", // Set an appropriate height
                          padding: "10px",
                          borderRadius: "5px",
                          borderColor: "#ced4da",
                          fontSize: "14px",
                          lineHeight: "1.5",
                        }}
                      />
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        value={code.evidenceType}
                        onChange={(e) =>
                          handleInputChange(e, "evidenceType", index)
                        }
                        className={
                          validationErrors[index]?.evidenceType
                            ? "border-danger"
                            : ""
                        }
                      >
                        <option value="">Select</option>
                        {evidenceTypes.map((type) => (
                          <option key={type.id} value={type.name}>
                            {type.name}
                          </option>
                        ))}
                      </Form.Control>
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        value={code.documentType}
                        onChange={(e) =>
                          handleInputChange(e, "documentType", index)
                        }
                        className={
                          validationErrors[index]?.documentType
                            ? "border-danger"
                            : ""
                        }
                      >
                        <option value="">Select</option>
                        {documentTypes.map((type) => (
                          <option key={type.id} value={type.name}>
                            {type.name}
                          </option>
                        ))}
                      </Form.Control>
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        value={code.encounterType}
                        onChange={(e) =>
                          handleInputChange(e, "encounterType", index)
                        }
                        className={
                          validationErrors[index]?.encounterType
                            ? "border-danger"
                            : ""
                        }
                      >
                        <option value="">Select</option>
                        {encounterTypes.map((type) => (
                          <option key={type.id} value={type.name}>
                            {type.name}
                          </option>
                        ))}
                      </Form.Control>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" className="text-center">
                    There is no data to display.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};
