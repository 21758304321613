import React from "react";
import { Row, Col, InputGroup, FormControl, Button } from "react-bootstrap";

const ManualReviewTableHeader = ({ search, handleSearchChange }) => (
  <Row className="align-items-center mb-3">
    <Col>
      <h4>Inbound to Honest</h4>
    </Col>
    <Col md={4} className="text-end">
      <InputGroup size="sm">
        <FormControl
          type="text"
          placeholder="Search..."
          value={search}
          onChange={handleSearchChange}
        />
        <Button variant="outline-secondary">Search</Button>
      </InputGroup>
    </Col>
  </Row>
);

export default ManualReviewTableHeader;
