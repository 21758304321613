import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate, Link, Navigate } from "react-router-dom";
import { Modal, Breadcrumb, Button, Container } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import CoderReviewPatientInfo from "./CoderReviewPatientInfo";
import {
  CoderReviewClaimsRecapture,
  CoderReviewSuspectDiagnosis,
  CoderReviewAddedICDCodes,
} from "./CoderReviewSections";
import { FaCheck, FaAngleLeft } from "react-icons/fa";
import { fetchData, patchData } from "../../api/apiService";
import usePermissions from "../../hooks/usePermissions";
import Loader from "../../ui/Layout/Loader";
import { io } from "socket.io-client";

const CoderReviewDetailComponent = () => {
  const { permissions, permissionsLoaded } = usePermissions();
  const { id } = useParams();
  const navigate = useNavigate();
  const ws = useRef(null);

  // Initialize state
  const [selectedRows, setSelectedRows] = useState({
    suspectDiagnosisCodes: {},
    addedICDCodes: {},
  });

  const [reviewData, setReviewData] = useState({
    addedICDCodes: [],
    claimsRecaptureCodes: [],
    suspectDiagnosisCodes: [],
  });
  const [formValues, setFormValues] = useState({});
  const [newICDCode, setNewICDCode] = useState({
    code: "",
    description: "",
    excerpt: "",
    sourceDate: "",
    evidenceType: "",
    documentType: "",
    encounterType: "",
  });
  const [documentTypes, setDocumentTypes] = useState([]);
  const [encounterTypes, setEncounterTypes] = useState([]);
  const [evidenceTypes, setEvidenceTypes] = useState([]);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmSubmitVisible, setConfirmSubmitVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchPatientData = async () => {
      try {
        let pvpUrl = `/pvps/${id}`;
        if (permissions?.includes("pvp_coder_review_edit")) {
          pvpUrl = `/pvps/${id}?isReview=true`;
        }
        const response = await fetchData(pvpUrl);
        setReviewData({
          ...response,
          addedICDCodes: response.addedICDCodes || [],
        });
        setFormValues({
          patientName: response.patientName,
          gender: response.gender,
          patientId: response.patientId,
          office: response.officeIpa,
          dob: response.dob,
          dateOfService: response.dateOfService,
          providerName: response.providerName,
          mbi: response.mbi,
        });
      } catch (error) {
        console.error("Error fetching patient data:", error);
        toast.error("Failed to fetch patient data", {
          position: "top-right",
        });
      }
    };

    const fetchSourceTypes = async () => {
      try {
        const response = await fetchData("/master/sourceList");
        setDocumentTypes(response.documentTypes || []);
        setEncounterTypes(response.encounterTypes || []);
        setEvidenceTypes(response.evidenceTypes || []);
      } catch (error) {
        console.error("Error fetching source types:", error);
        toast.error("Failed to fetch source types", {
          position: "top-right",
        });
      }
    };

    if (permissionsLoaded) {
      fetchSourceTypes();
      fetchPatientData();
    }

    const handleBeforeUnload = (event) => {
      // Call the removePVPReview function when the user tries to leave the page
      removePVPReview();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [id, permissionsLoaded, permissions]);

  useEffect(() => {
  // Initialize WebSocket connection
  ws.current = io(process.env.REACT_APP_BASE_URL);

  ws.current.on("connect", () => {
    console.log("Connected - add WIP");
  });

  ws.current.on("disconnect", () => {
    console.log("Disconnected - remove WIP");
    // Call the async removePVPReview within an IIFE
    (async () => {
      await patchData(`/pvps/${id}/removePVPReview`);
    })();
  });

  ws.current.on("error", (error) => {
    console.error("WebSocket error:", error);
  });

  // Cleanup function
  return () => {
    (async () => {
      await patchData(`/pvps/${id}/removePVPReview`);
    })();
    
    ws.current.close(); // Close the WebSocket connection
  };
}, []);


  const handleInputChange = (
    e,
    field,
    index = -1,
    section = "addedICDCodes"
  ) => {
    if (index === -1) {
      setNewICDCode((prev) => ({ ...prev, [field]: e.target.value }));
    } else {
      setReviewData((prev) => {
        const updatedCodes = [...prev[section]];
        updatedCodes[index] = {
          ...updatedCodes[index],
          [field]: e.target.value,
          changed: true, // Mark row as changed
        };
        return { ...prev, [section]: updatedCodes };
      });
    }
    setUnsavedChanges(true);
  };

  const handleAddRow = () => {
    const newIndex = reviewData.addedICDCodes.length;

    const newRow = {
      code: newICDCode.code,
      description: newICDCode.description,
      excerpt: newICDCode.excerpt,
      sourceDate: newICDCode.sourceDate,
      evidenceType: newICDCode.evidenceType,
      documentType: newICDCode.documentType,
      encounterType: newICDCode.encounterType,
      mvpCategory: "Coder bucket", // Assuming this is the default for new rows
      isNew: true, // Mark this row as newly added
    };

    setReviewData((prev) => ({
      ...prev,
      addedICDCodes: [...prev.addedICDCodes, newRow],
    }));

    setSelectedRows((prevSelectedRows) => ({
      ...prevSelectedRows,
      addedICDCodes: {
        ...prevSelectedRows.addedICDCodes,
        [newIndex]: false, // Automatically select the newly added row
      },
    }));

    setNewICDCode({
      code: "",
      description: "",
      excerpt: "",
      sourceDate: "",
      evidenceType: "",
      documentType: "",
      encounterType: "",
    });

    setUnsavedChanges(true);
  };

  const isRowFilled = (code) => {
    return (
      code &&
      typeof code === "object" &&
      Object.values(code).every(
        (value) => value !== "" && value !== null && value !== undefined
      )
    );
  };

  const isRowFilledAddedSuspects = (code) => {
    const requiredFields = [
      "code",
      "description",
      "excerpt",
      "sourceDate",
      "evidenceType",
      "documentType",
      "encounterType",
    ];

    return requiredFields.every((field) => {
      const value = code[field];
      // Ensure trim is only called on strings
      if (typeof value === "string") {
        return value.trim() !== "";
      }
      // Handle other types (e.g., null, undefined, or non-string values)
      return value !== null && value !== undefined;
    });
  };

  const handleRowSelection = (index, section) => {
    const selectedRow = reviewData[section] ? reviewData[section][index] : null;
    if (selectedRow) {
      setSelectedRows((prevSelectedRows) => {
        const isCurrentlySelected = prevSelectedRows[section]?.[index];

        // Skip validation for "suspectDiagnosisCodes" section
        if (
          section !== "suspectDiagnosisCodes" &&
          !isCurrentlySelected &&
          !isRowFilledAddedSuspects(selectedRow)
        ) {
          toast.error("All fields must be filled before selecting.", {
            position: "top-right",
          });
          return prevSelectedRows;
        }

        const updatedSelection = {
          ...prevSelectedRows,
          [section]: {
            ...prevSelectedRows[section],
            [index]: !isCurrentlySelected,
          },
        };

        setReviewData((prev) => {
          const updatedCodes = [...prev[section]];
          updatedCodes[index] = {
            ...updatedCodes[index],
            mvpCategory: updatedSelection[section][index]
              ? "Coder bucket"
              : null,
            changed: true,
          };
          return { ...prev, [section]: updatedCodes };
        });

        return updatedSelection;
      });

      setUnsavedChanges(true);
    }
  };

  const hasValidationError = () => {
    return reviewData.addedICDCodes.some((code) => !isRowFilled(code));
  };

  const handleSubmit = () => {
    const hasEmptyICDCode = reviewData.addedICDCodes.some(
      (code) => !code.code.trim()
    );
  
    const hasEmptySuspectICDCode = reviewData.suspectDiagnosisCodes.some(
      (code, index) => {
        return selectedRows.suspectDiagnosisCodes[index] && !code.code.trim();
      }
    );
  
    if (hasEmptyICDCode || hasEmptySuspectICDCode) {
      toast.error("ICD code cannot be empty.", { position: "top-right" });
      return;
    }
  
    // Count modified or newly added suspect diagnosis codes
    const changedSuspectDiagnosisCount =
      reviewData.suspectDiagnosisCodes.reduce((count, code, index) => {
        return (code.changed &&
          selectedRows.suspectDiagnosisCodes[index] !== undefined) ||
          selectedRows.suspectDiagnosisCodes[index] !== undefined
          ? count + 1
          : count;
      }, 0);
  
    // Count modified or newly added ICD codes in the "addedICDCodes" section
    const changedAddedICDCount = reviewData.addedICDCodes.reduce(
      (count, code, index) => {
        // Ensure the row is either changed or newly added AND isSubmitted is true
        return (code.changed || code.isNew) && selectedRows.addedICDCodes[index]
          ? count + 1
          : count;
      },
      0
    );
  
    const totalCount = changedAddedICDCount + changedSuspectDiagnosisCount;
  
    if (totalCount === 0) {
      setModalTitle("Submit without changes to any records?");
      setModalMessage(
        "Clicking submit will update the patient records without any modifications."
      );
    } else {
      setModalTitle(`Submit changes to ${totalCount} records?`);
      setModalMessage(
        "Clicking submit will update the patient records that were selected or modified based on your review."
      );
    }
    setConfirmSubmitVisible(true);
  };
  

  const confirmSubmit = async () => {
    setIsLoading(true);
    setConfirmSubmitVisible(false);

    const getFormattedDate = () => {
      const date = new Date();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${month}/${day}/${year}`;
    };

    const convertSourceDate = (date) => {
      console.log("date", date);

      // Check if date is already a Date object, otherwise, convert the string to Date
      const parsedDate = typeof date === "string" ? new Date(date) : date;

      if (!parsedDate || isNaN(parsedDate)) {
        return "";
      }
      
      const month = (parsedDate.getMonth() + 1).toString().padStart(2, "0");
      const day = parsedDate.getDate().toString().padStart(2, "0");
      const year = parsedDate.getFullYear();
      return `${month}/${day}/${year}`;
    };

    const preparedData = {
      PVP_ID: reviewData.id || "",
      PATIENTID: reviewData.patientId || "",
      FNAME: reviewData.patientName?.split(" ")[0] || "",
      LNAME: reviewData.patientName?.split(" ")[1] || "",
      MBI: reviewData.mbi || "",
      SUBSCRIBER_ID: "",
      JVPARTNER_ID: "",
      JVPNAME: reviewData.jvp || "",
      ADDRESS1: "",
      ADDRESS2: "",
      CITY: "",
      STATE: "",
      ZIPCODE: "",
      DOB: reviewData.dob || "",
      HOME_PHONE: "",
      PCP_NPI: "",
      PROVIDER_NPI: "",
      PROVIDER_FNAME: "",
      PROVIDER_LNAME: "",
      SCHEDULED_DATE: reviewData.apptDate || "",
      PVP_DATE: reviewData.pvpDate || "",
      DATE_OF_SERVICE: reviewData.dateOfService || "",
      ICD_Details: [
        // Process claimsRecaptureCodes normally
        ...reviewData.claimsRecaptureCodes.map((code) => ({
          ICDDIAG_CODE_DESCRIPTIVE:
            code.code.length > 3
              ? `${code.code.slice(0, 3)}.${code.code.slice(3)}`
              : code.code,
          DGNS_CODE_DESC: code.description,
          RECAP_DOS: code.dateOfService || "",
          HCC: "",
          SUPPORTING_DOCUMENTATION: "",
          ICD_SOURCE_TYPE: "Claims Recapture",
          isSubmitted: true,
          ICD_SOURCE_STATUS: "",
          MVP_CATEGORY: "Auto PVP",
          category: "Coder Review – Approved",
        })),
    
        // Process suspectDiagnosisCodes without filtering
        ...reviewData.suspectDiagnosisCodes.map((code, index) => {
          const selected = selectedRows.suspectDiagnosisCodes[index];
          let isSubmitted = false;
          let category = "";
    
          if (selected) {
            isSubmitted = true;
            category = "Coder Review – Approved";
          } else if (!selected) {
            isSubmitted = false;
            category = "Coder Review – Rejected";
          } else if (code.mvpCategory === "Auto PVP") {
            isSubmitted = true;
            category = "Coder Review – Approved";
          }
    
          return {
            ICDDIAG_CODE_DESCRIPTIVE:
              code.code.length > 3
                ? `${code.code.slice(0, 3)}.${code.code.slice(3)}`
                : code.code,
            DGNS_CODE_DESC: code.description,
            RECAP_DOS: code.dateOfService || "",
            HCC: "",
            ICD_SOURCE_TYPE: "Suspect Diagnosis",
            SD_SOURCE_DATE: convertSourceDate(code.dateOfService) || "",
            ICD_SOURCE_STATUS: "",
            MVP_CATEGORY: code.mvpCategory || "",
            Source_Date: convertSourceDate(code.dateOfService) || "",
            Document_Type: code.sourceDocumentType || "",
            Evidence_Type: code.sourceEvidenceType || "",
            Encounter_Type: code.sourceEncounterType || "",
            Excerpt: code.sourceExcerpt || "",
            isSubmitted: isSubmitted,
            category: category,
            outcome: selected ? "Add" : "Delete",
            outcomeDate: getFormattedDate(),
          };
        }),
    
        // Filter and process addedICDCodes where isSubmitted is true
        ...reviewData.addedICDCodes
          .filter((code, index) => selectedRows.addedICDCodes[index]) // Only include selected rows
          .map((code, index) => ({
            ICDDIAG_CODE_DESCRIPTIVE:
              code.code.length > 3
                ? `${code.code.slice(0, 3)}.${code.code.slice(3)}`
                : code.code,
            DGNS_CODE_DESC: code.description,
            RECAP_DOS: code.sourceDate || "",
            HCC: code.hcc || "",
            ICD_SOURCE_TYPE: "Suspect Diagnosis",
            SD_SOURCE_DATE: convertSourceDate(code.sourceDate) || "",
            ICD_SOURCE_STATUS: "",
            MVP_CATEGORY: "Coder bucket",
            Source_Date: convertSourceDate(code.sourceDate) || "",
            Document_Type: code.documentType || "",
            Evidence_Type: code.evidenceType || "",
            Encounter_Type: code.encounterType || "",
            Excerpt: code.excerpt || "",
            isSubmitted: true,
            category: "Coder Review – Add",
            outcome: "Add",
            outcomeDate: getFormattedDate(),
          })),
      ],
      SUBMITTED_DATE: "",
      PDF_GEN_REQUEST_DATE: "",
      PDF_GEN_COMPLETION_DATE: "",
      PDF_METADATA_FILENAME: "",
      CDATE: new Date().toISOString(),
      MDATE: new Date().toISOString(),
    };
    
    console.log('prepared data', preparedData);
    try {
      await patchData(`/pvps/editPvp/${reviewData.id}`, preparedData);
      toast.success("Data submitted successfully!", {
        position: "top-right",
      });
      setUnsavedChanges(false);

      setTimeout(() => {
        navigate("/pvp/coder_review_list");
      }, 2000);
    } catch (error) {
      console.error("Error submitting data:", error);
      toast.error("Submission failed!", {
        position: "top-right",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const removePVPReview = async () => {
    try {
      const response = await patchData(`/pvps/${id}/removePVPReview`);
      if (response.status !== 200) {
        // throw new Error("Failed to remove PVP review");
      }
      navigate("/pvp/coder_review_list");
    } catch (error) {
      console.error("Error removing PVP review:", error);
      // toast.error("Failed to remove PVP review", {
      //   position: "top-right",
      // });
    }
  };

  const handleBackButtonClick = async () => {
    if (unsavedChanges) {
      setModalVisible(true);
    } else {
      await removePVPReview();
    }
  };

  const handleModalContinue = async () => {
    setModalVisible(false);
    await removePVPReview();
  };

  const handleModalCancel = () => {
    setModalVisible(false);
  };

  const handleBreadcrumbClick = async (e) => {
    e.preventDefault();
    await removePVPReview();
    navigate("/pvp");
  };
  const handleBreadcrumbClickReview = async (e) => {
    e.preventDefault();
    await removePVPReview();
    navigate("/pvp/coder_review_list");
  };

  const manualNavigate = async (path) => {
    await removePVPReview();
    navigate(path);
  };

  if (!permissionsLoaded) {
    return <Loader />;
  }

  return (
    <div>
      {permissions?.includes("pvp_coder_review_list") ? (
        <div>
          <Container className="mt-2 px-0 pvp-back-to-list">
            <Breadcrumb className="pvp-detail-breadcrumb">
              <Breadcrumb.Item>
                <Link
                  className="text-dark link"
                  to="/pvp"
                  onClick={handleBreadcrumbClick}
                >
                  PVP
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item autoFocus>
                <Link
                  className="text-dark link"
                  to="/pvp/coder_review_list"
                  onClick={handleBreadcrumbClickReview}
                >
                  Outbound to Provider
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Patient Details</Breadcrumb.Item>
            </Breadcrumb>
            <div className="d-flex justify-content-end">
              <Button
                variant="primary"
                onClick={handleBackButtonClick}
                className="primary-btn"
              >
                <FaAngleLeft className="me-2" />
                Back to Patient Selection
              </Button>
            </div>
          </Container>
          <Container className="px-0 pt-3 mt-2 pvp-form border-0">
            <CoderReviewPatientInfo formValues={formValues} />
          </Container>
          <Container className="border rounded px-3 pt-3 mt-2">
            <div className="coder-review-view">
              <div className="review-content">
                <div className="coder-form-section pt-0 px-0">
                  <div className="pvp-form pvp-coder-form border-0">
                    <div className="pvp-sub-section rounded mt-2">
                      {reviewData && (
                        <CoderReviewClaimsRecapture reviewData={reviewData} />
                      )}
                      {reviewData && (
                        <CoderReviewSuspectDiagnosis
                          reviewData={reviewData}
                          selectedRows={selectedRows.suspectDiagnosisCodes}
                          handleRowSelection={handleRowSelection}
                        />
                      )}
                      {reviewData && (
                        <CoderReviewAddedICDCodes
                          reviewData={reviewData}
                          newICDCode={newICDCode}
                          handleInputChange={handleInputChange}
                          handleAddRow={handleAddRow}
                          documentTypes={documentTypes}
                          encounterTypes={encounterTypes}
                          evidenceTypes={evidenceTypes}
                          selectedRows={selectedRows.addedICDCodes}
                          handleRowSelection={handleRowSelection}
                        />
                      )}
                      {permissions?.includes("pvp_coder_review_edit") ? (
                        <div className="d-flex justify-content-end mt-5">
                          <Button
                            className="primary-btn mb-4 font-size-14"
                            onClick={handleSubmit}
                            disabled={isLoading || hasValidationError()}
                          >
                            {isLoading ? (
                              <span
                                className="spinner-border spinner-border-sm me-2"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            ) : (
                              <FaCheck className="me-2" />
                            )}
                            {isLoading ? "Submitting..." : "Submit"}
                          </Button>
                          <br></br>
                          <br></br>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <ToastContainer />
            </div>
          </Container>

          <Modal
            show={confirmSubmitVisible}
            onHide={() => setConfirmSubmitVisible(false)}
            className="top-right-modal modal-transparent"
            backdrop={false}
            centered={false}
          >
            <Modal.Header closeButton className="py-2 px-2">
              <Modal.Title className="text-15 font-weight-600">
                {modalTitle}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-15 single-line-text px-2">
              {modalMessage}
            </Modal.Body>
            <Modal.Footer className="py-1">
              <Button
                variant="light border"
                onClick={() => setConfirmSubmitVisible(false)}
                className="text-13"
              >
                Cancel
              </Button>
              <Button className="primary-btn text-13" onClick={confirmSubmit}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={modalVisible} onHide={handleModalCancel}>
            <Modal.Header closeButton>
              <Modal.Title>Unsaved Data</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              You have unsaved data, do you wish to cancel work on this PVP?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleModalCancel}>
                No, Continue
              </Button>
              <Button className="primary-btn" onClick={handleModalContinue}>
                Yes, Cancel PVP
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      ) : (
        <Navigate to="/401" replace />
      )}
    </div>
  );
};

export default CoderReviewDetailComponent;
