import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Table,
  Row,
  Col,
  InputGroup,
  FormControl,
  Pagination,
  Breadcrumb,
  Spinner,
  Overlay,
  Popover,
} from "react-bootstrap";
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";
import { Navigate, Link } from "react-router-dom";
import { IoSearchSharp } from "react-icons/io5";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { io } from "socket.io-client";
import { fetchData } from "../../api/apiService";
import usePermissions from "../../hooks/usePermissions";
import Loader from "../../ui/Layout/Loader";

const CoderListComponent = () => {
  const { permissions, permissionsLoaded } = usePermissions();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [coderListPage, setCoderListPage] = useState(
    parseInt(localStorage.getItem("coderListPage")) || 1
  );
  const [itemsPerPage] = useState(10);

  // Set default sorting for JVP and Appt Date to "asc", others to "none"
  const [sortState, setSortState] = useState({
    jvpName: "asc", // Default to ascending for JVP
    practiceName: "none",
    icd10Codes: "none",
    apptDate: "asc", // Default to ascending for Appt Date
    underReviewBy: "none",
  });

  const [searchQueries, setSearchQueries] = useState({
    patientName: "",
    mbi: "",
    underReviewBy: "",
    jvp: [],
    practiceName: [],
  });

  const [jvpOptions, setJvpOptions] = useState([]);
  const [practiceOptions, setPracticeOptions] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  const ws = useRef(null);

  const localStorageUserId = parseInt(localStorage.getItem("user_id"));

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchPatientData();
  }, []);

  useEffect(() => {
    ws.current = io(process.env.REACT_APP_BASE_URL);
    ws.current.on("connect", () => {
      ws.current.emit("coderRequestTableData");
    });
    ws.current.on("coderTableData", () => {
      const savedPage =
        parseInt(localStorage.getItem("coderListPage")) || coderListPage;
      fetchPatientData(false, savedPage);
    });
    ws.current.on("disconnect", () => {
      console.log("Disconnected from WebSocket server");
    });
    ws.current.on("error", (error) => {
      console.error("WebSocket error:", error);
    });
    return () => {
      ws.current.close();
    };
  }, []);

  useEffect(() => {
    fetchPatientData();
  }, [coderListPage, sortState, searchQueries.jvp, searchQueries.practiceName]);

  const fetchPatientData = async (showLoader = true, page = coderListPage) => {
    if (showLoader) {
      setLoading(true);
    }
    try {
      const searchFields = [];
      const searchValues = [];

      if (searchQueries.patientName) {
        searchFields.push("patientName");
        searchValues.push(searchQueries.patientName);
      }

      if (searchQueries.mbi) {
        searchFields.push("mbi");
        searchValues.push(searchQueries.mbi);
      }

      if (searchQueries.underReviewBy) {
        searchFields.push("underReviewBy");
        searchValues.push(searchQueries.underReviewBy);
      }

      const queryParams = new URLSearchParams({
        page: page,
        limit: itemsPerPage,
      });

      const sortBy = [];
      const sortOrder = [];
      Object.keys(sortState).forEach((key) => {
        if (sortState[key] !== "none") {
          sortBy.push(key);
          sortOrder.push(sortState[key]);
        }
      });

      if (sortBy.length > 0) {
        queryParams.append("sortBy", sortBy.join(","));
        queryParams.append("sortOrder", sortOrder.join(","));
      }

      const jvpName = searchQueries.jvp.map((jvp) => jvp.value).join(",");
      const practiceName = searchQueries.practiceName
        .map((practice) => practice.value)
        .join(",");

      if (jvpName) {
        queryParams.append("jvpName", jvpName);
      }

      if (practiceName) {
        queryParams.append("practiceName", practiceName);
      }

      if (searchFields.length > 0) {
        queryParams.append("searchFields", searchFields.join(","));
        queryParams.append("searchValues", searchValues.join(","));
      }

      const response = await fetchData(`/pvps?${queryParams.toString()}`);

      setData(response.pvps || []);
      setTotalItems(response.pvpsCount || 0);

      const jvpOptions = response.jvpPractices.map((item) => ({
        value: item.jvpName,
        label: item.jvpName,
        practices: item.practiceNames.map((practice) => ({
          value: practice,
          label: practice,
        })),
      }));
      setJvpOptions(jvpOptions);
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Failed to fetch data", {
        position: "top-right",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSort = (field) => {
    setSortState((prevState) => {
      const currentOrder = prevState[field];
      let newOrder = "none";

      if (currentOrder === "none") {
        newOrder = "asc";
      } else if (currentOrder === "asc") {
        newOrder = "desc";
      }

      return {
        ...prevState,
        [field]: newOrder,
      };
    });
  };

  const renderSortIcon = (field) => {
    const sortOrder = sortState[field];
    if (sortOrder === "asc") return <FaSortUp />;
    if (sortOrder === "desc") return <FaSortDown />;
    return <FaSort />;
  };

  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setSearchQueries((prev) => ({ ...prev, [name]: value }));
  };

  const handleSearchKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearchIconClick();
    }
  };

  const handleSearchIconClick = () => {
    fetchPatientData();
    ws.current.emit("coderSearch", searchQueries);
  };

  const handleJvpChange = (selectedOptions) => {
    setSearchQueries((prev) => ({
      ...prev,
      jvp: selectedOptions || [],
      practiceName: [],
    }));
    setPracticeOptions(
      selectedOptions
        ? selectedOptions.flatMap((option) => option.practices)
        : []
    );
  };

  const handlePracticeChange = (selectedOptions) => {
    setSearchQueries((prev) => ({
      ...prev,
      practiceName: selectedOptions || [],
    }));
  };

  const paginate = (pageNumber) => {
    setCoderListPage(pageNumber);
    localStorage.setItem("coderListPage", pageNumber);
    ws.current.emit("coderPaginate", pageNumber);
  };

  const copyToClipboard = (text, event) => {
    navigator.clipboard.writeText(text);
    setShow(true);
    setTarget(event.target);
    setTimeout(() => {
      setShow(false);
    }, 1000);
  };

  if (!permissionsLoaded) {
    return <Loader />;
  }

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  return (
    <div>
      {permissions?.includes("pvp_coder_review_list") ? (
        <div ref={ref}>
          <Container className="mt-2 px-0">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link className="text-dark link" to="/pvp">
                  PVP
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Outbound to Provider</Breadcrumb.Item>
            </Breadcrumb>
            <Row className="mt-4 mb-2 justify-content-between">
              <Col>
                <h4 className="">Outbound to Provider</h4>
              </Col>
            </Row>
          </Container>
          <Container className="border rounded px-4 pt-3 mt-2">
            <Row className="mb-3 align-items-center">
              <Col md={2}>
                <InputGroup>
                  <FormControl
                    placeholder="Search Patient Name"
                    name="patientName"
                    onChange={handleSearchChange}
                    onKeyDown={handleSearchKeyDown}
                    className="font-size-12"
                    value={searchQueries.patientName}
                  />
                  <InputGroup.Text
                    id="basic-addon1"
                    style={{ cursor: "pointer" }}
                    onClick={handleSearchIconClick}
                  >
                    <IoSearchSharp />
                  </InputGroup.Text>
                </InputGroup>
              </Col>
              <Col md={2}>
                <InputGroup>
                  <FormControl
                    placeholder="Search MBI"
                    name="mbi"
                    onChange={handleSearchChange}
                    onKeyDown={handleSearchKeyDown}
                    className="font-size-12"
                    value={searchQueries.mbi}
                  />
                  <InputGroup.Text
                    id="basic-addon1"
                    style={{ cursor: "pointer" }}
                    onClick={handleSearchIconClick}
                  >
                    <IoSearchSharp />
                  </InputGroup.Text>
                </InputGroup>
              </Col>
              <Col md={2}>
                <InputGroup>
                  <FormControl
                    placeholder="Search Medical Coder"
                    name="underReviewBy"
                    onChange={handleSearchChange}
                    onKeyDown={handleSearchKeyDown}
                    className="font-size-12"
                    value={searchQueries.underReviewBy || ""}
                  />
                  <InputGroup.Text
                    id="basic-addon1"
                    style={{ cursor: "pointer" }}
                    onClick={handleSearchIconClick}
                  >
                    <IoSearchSharp />
                  </InputGroup.Text>
                </InputGroup>
              </Col>
              <Col md={2}>
                <Select
                  options={jvpOptions}
                  onChange={handleJvpChange}
                  isMulti
                  placeholder="Select JVP"
                  className="font-size-12"
                />
              </Col>
              <Col md={3}>
                <Select
                  options={practiceOptions}
                  onChange={handlePracticeChange}
                  isMulti
                  placeholder="Select Practice Name"
                  className="font-size-12"
                  isDisabled={searchQueries.jvp.length === 0}
                />
              </Col>
            </Row>
            <hr></hr>
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "300px" }}
              >
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <Row className="px-2">
                <Table striped bordered hover className="pvp-list-table">
                  <thead>
                    <tr>
                      <th style={{ width: "15%" }}>
                        <div style={{ cursor: "default" }}>Patient Name</div>
                      </th>
                      <th style={{ width: "10%" }}>
                        <div style={{ cursor: "default" }}>MBI</div>
                      </th>
                      <th
                        onClick={() => handleSort("jvpName")}
                        style={{ cursor: "pointer", width: "10%" }}
                      >
                        JVP {renderSortIcon("jvpName")}
                      </th>
                      <th
                        onClick={() => handleSort("practiceName")}
                        style={{ cursor: "pointer", width: "15%" }}
                      >
                        Practice Name {renderSortIcon("practiceName")}
                      </th>
                      <th style={{ width: "8%" }}>
                        <div style={{ cursor: "default" }}>DOB</div>
                      </th>
                      <th
                        onClick={() => handleSort("icd10Codes")}
                        style={{ cursor: "pointer", width: "12%" }}
                      >
                        ICD-10 Count {renderSortIcon("icd10Codes")}
                      </th>
                      <th
                        onClick={() => handleSort("apptDate")}
                        style={{ cursor: "pointer", width: "10%" }}
                      >
                        Appt. Date {renderSortIcon("apptDate")}
                      </th>
                      <th
                        onClick={() => handleSort("underReviewBy")}
                        style={{ cursor: "pointer", width: "15%" }}
                      >
                        Medical Coder {renderSortIcon("underReviewBy")}
                      </th>
                      <th style={{ width: "5%" }}>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 ? (
                      data.map((item) => (
                        <tr
                          key={item.id}
                          className={item.underReviewBy ? "under-review" : ""}
                        >
                          <td>
                            {item.underReviewById === null ||
                            item.underReviewById === localStorageUserId ? (
                              <div>
                                <Link
                                  to={`/pvp/coder_review_list/${item.id}`}
                                  className={
                                    item.underReviewBy ? "text-muted" : ""
                                  }
                                >
                                  {item.patientName}
                                </Link>
                              </div>
                            ) : (
                              <span
                                className={
                                  item.underReviewBy ? "text-muted" : ""
                                }
                              >
                                {item.patientName}
                              </span>
                            )}
                          </td>
                          <td>
                            <span
                              className={item.underReviewBy ? "text-muted" : ""}
                            >
                              {item.mbi}
                            </span>
                          </td>
                          <td>
                            <span
                              className={item.underReviewBy ? "text-muted" : ""}
                            >
                              {item.jvp}
                            </span>
                          </td>
                          <td>
                            <span
                              className={item.underReviewBy ? "text-muted" : ""}
                            >
                              {item.practiceName}
                            </span>
                          </td>
                          <td>
                            <span
                              className={item.underReviewBy ? "text-muted" : ""}
                            >
                              {item.dob}
                            </span>
                          </td>
                          <td>
                            <span
                              className={item.underReviewBy ? "text-muted" : ""}
                            >
                              {item.icd10Codes}
                            </span>
                          </td>
                          <td>
                            <span
                              className={item.underReviewBy ? "text-muted" : ""}
                            >
                              {item.apptDate}
                            </span>
                          </td>
                          <td>
                            <span
                              className={item.underReviewBy ? "text-muted" : ""}
                            >
                              {item.underReviewBy}
                            </span>
                          </td>
                          <td>{item.underReviewBy ? "WIP" : "To Do"}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="9" className="text-center">
                          There is no data to display
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="font-size-14">
                    Page {coderListPage} of {totalPages}
                  </div>
                  <Pagination className="d-grid p-0 d-md-flex justify-content-md-end">
                    <Pagination.First
                      onClick={() => paginate(1)}
                      disabled={coderListPage === 1}
                    />
                    <Pagination.Prev
                      onClick={() => paginate(coderListPage - 1)}
                      disabled={coderListPage === 1}
                    />
                    {(() => {
                      const maxPageNumbersToShow = 5;
                      const halfPageNumbersToShow = Math.floor(
                        maxPageNumbersToShow / 2
                      );

                      let startPage = Math.max(
                        coderListPage - halfPageNumbersToShow,
                        1
                      );
                      let endPage = Math.min(
                        startPage + maxPageNumbersToShow - 1,
                        totalPages
                      );

                      if (endPage - startPage + 1 < maxPageNumbersToShow) {
                        startPage = Math.max(
                          endPage - maxPageNumbersToShow + 1,
                          1
                        );
                      }

                      const pages = [];
                      for (let i = startPage; i <= endPage; i++) {
                        pages.push(
                          <Pagination.Item
                            key={i}
                            active={i === coderListPage}
                            onClick={() => paginate(i)}
                          >
                            {i}
                          </Pagination.Item>
                        );
                      }

                      return (
                        <>
                          {startPage > 1 && <Pagination.Ellipsis disabled />}
                          {pages}
                          {endPage < totalPages && (
                            <Pagination.Ellipsis disabled />
                          )}
                        </>
                      );
                    })()}
                    <Pagination.Next
                      onClick={() => paginate(coderListPage + 1)}
                      disabled={coderListPage === totalPages}
                    />
                    <Pagination.Last
                      onClick={() => paginate(totalPages)}
                      disabled={coderListPage === totalPages}
                    />
                  </Pagination>
                </div>
              </Row>
            )}
          </Container>
          <ToastContainer />
        </div>
      ) : (
        <Navigate to="/401" replace />
      )}
    </div>
  );
};

export default CoderListComponent;
