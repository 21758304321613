import { Container, Button, Form, Row, Col } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./RoleManagement.css"; // Import custom CSS for styling
import Loader from "../../ui/Layout/Loader";
import { Navigate } from "react-router-dom";
import { fetchUserPermission } from "../../components/SecurityComponent/SecurityComponent";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import CSS for react-toastify
import { fetchData, postData } from "../../api/apiService"; // Import fetchData and postData from apiService

const RoleManagement = ({ roles, components, onSubmit }) => {
  const [checkedItems, setCheckedItems] = useState({});
  const [permissions, setPermissions] = useState([]);
  const [permissionsLoaded, setPermissionsLoaded] = useState(false);
  const [formData, setFormData] = useState({ name: "" });
  const [selectedRoleId, setSelectedRoleId] = useState(""); // Track selected role ID
  const [allComponents, setAllComponents] = useState([]); // To store all components

  const { instance } = useMsal();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userPermission = await fetchUserPermission();
        setPermissions(userPermission.dev_security_label);
        setPermissionsLoaded(true);
      } catch (error) {
        if (error.statusCode === 401) {
          instance.logoutRedirect({ postLogoutRedirectUri: "/" });
        }
        if (error.statusCode === 403) {
          navigate("/403");
        }
      }
    };

    fetchData();
  }, [instance, navigate]);

  useEffect(() => {
    const fetchAllComponents = async () => {
      try {
        const result = await fetchData(`/components`);
        setAllComponents(result.components);
      } catch (error) {
        console.error('Error fetching all components:', error);
      }
    };

    fetchAllComponents();
  }, []);

  useEffect(() => {
    if (selectedRoleId) {
      const fetchComponents = async () => {
        try {
          const result = await fetchData(`/roles/${selectedRoleId}/components`);
          console.log('Fetched components:', result);

          // Map component ids from the selected role to checkedItems
          const newCheckedItems = {};
          result.components.forEach(component => {
            newCheckedItems[component.id] = true;
          });
          console.log('ne', newCheckedItems);
          setCheckedItems(newCheckedItems);
        } catch (error) {
          console.error('Error fetching role components:', error);
        }
      };

      fetchComponents();
    }
  }, [selectedRoleId]);

  if (!permissionsLoaded) {
    return <Loader />;
  }

  const toggleCheckbox = (id) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [id]: !prevCheckedItems[id],
    }));
  };

  const handleSubmitPermission = async () => {
    const selectedCheckboxes = Object.keys(checkedItems).filter(
      (key) => checkedItems[key]
    ).map(Number); // Convert keys to numbers

    console.log('selectedCheckboxes', selectedCheckboxes);

    try {
      await postData(`/roles/${selectedRoleId}/assignPermissions`, {
        componentIds: selectedCheckboxes,
      });
      toast.success('Permissions updated successfully.');
    } catch (error) {
      console.error('Error assigning permissions:', error);
      toast.error('Failed to update permissions.');
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!formData.name) {
      toast.error('Role name is required.');
      return;
    }
    onSubmit(formData);
    setFormData({ name: "" });
  };

  const handleRoleChange = (event) => {
    const selectedId = event.target.value;
    setSelectedRoleId(selectedId);
  };

  const renderCheckboxes = (parentItem, parentLabel, level = 0) => {
    const hasMultipleChildren =
      parentItem.children && parentItem.children.length > 1;
    const containerClass = hasMultipleChildren
      ? "horizontal-checkbox-container"
      : "";

    return (
      <Form.Group key={parentLabel}>
        <div
          className={`ml-${level * 3} ${containerClass}`}
          style={{ marginTop: level * 3 }}
        >
          {parentItem.children.map((child, index) => (
            <React.Fragment key={`${parentLabel}-${child.label}-${index}`}>
              <div style={{ marginLeft: level * 20 }}>
                {Array(level * 2).fill(" ").join(" ")}
                <Form.Check
                  type="checkbox"
                  id={`${parentLabel}-${child.label}-${index}`}
                  label={child.label}
                  checked={
                    checkedItems[child.id] || false // Use child.id for checking
                  }
                  onChange={() =>
                    toggleCheckbox(child.id)
                  }
                  className={`nested-checkbox ${
                    hasMultipleChildren ? "horizontal-checkbox" : ""
                  }`}
                />
              </div>
              {child.children &&
                renderCheckboxes(
                  child,
                  `${parentLabel}-${child.label}`,
                  level + 1
                )}
            </React.Fragment>
          ))}
        </div>
      </Form.Group>
    );
  };

  const generateHierarchy = (data, parentId = null) => {
    if (!data || !Array.isArray(data)) {
      console.error("Invalid data provided. Expected an array.");
      return [];
    }

    return data
      .filter((item) => item.parentId === parentId)
      .map((item) => {
        const children = generateHierarchy(data, item.id);
        return {
          id: item.id, // Include the id here
          label: item.name,
          children: children.length ? children : undefined,
        };
      });
  };

  const parentCheckbox = {
    label: "Parent",
    children: generateHierarchy(allComponents),
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <>
      {permissions?.includes("home_role_management") ? (
        <Container className="border border-light rounded px-5 mt-2">
          <h3>Role Management</h3>
          <Row className="m-4">
            <Tabs
              defaultActiveKey="role_management"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="role_management" title="Role Management">
                <Form>
                  <Row>
                    <Col xs={12} md={6} lg={4}>
                      <Form.Group controlId="configName">
                        <Form.Label>Role</Form.Label>
                        <Form.Select onChange={handleRoleChange}>
                          <option value="">Select Role</option>
                          {roles.map((option, index) => (
                            <option key={index} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6} lg={8}>
                      <Form.Group>
                        <Form.Label as="legend" column sm={2}>
                          &nbsp;
                        </Form.Label>
                        <Col sm={10}>
                          {renderCheckboxes(parentCheckbox, "Parent")}
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-4">
                    <Button variant="secondary" onClick={() => setCheckedItems({})}>
                      Reset
                    </Button>
                    <Button
                      className="primary-btn"
                      type="button"
                      onClick={handleSubmitPermission}
                    >
                      Submit
                    </Button>
                  </div>
                </Form>
              </Tab>
              <Tab eventKey="create_role" title="Create Role">
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col xs={12} md={6} lg={4}>
                      <Form.Group controlId="configName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Role Name"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-4">
                    <Button variant="secondary">Close</Button>
                    <Button variant="primary" type="submit">
                      Submit
                    </Button>
                  </div>
                </Form>
              </Tab>
            </Tabs>
          </Row>
          <ToastContainer />
        </Container>
      ) : (
        <Navigate to="/401" replace />
      )}
    </>
  );
};

export default RoleManagement;
