import React, { useState, useEffect } from 'react';
import UserManagement from "../../../components/UserManagement/UserManagement";
import { fetchData } from "../../../api/apiService";

const UserManagementPage = () => {
  const [roles, setRoles] = useState([]);
  useEffect(() => {
    const fetchRoles = async () => {
        // setLoading(true);
        try {
            const fetchedData = await fetchData('/roles');
            setRoles(fetchedData);
        } catch (error) {
            // setError(error);
        }
        // setLoading(false);
    };

    fetchRoles();
}, []);
  return <UserManagement roles={roles}></UserManagement>;
};

export default UserManagementPage;
