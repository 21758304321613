import React from "react";
import { Table, Button } from "react-bootstrap";
import { FaLock } from "react-icons/fa";

const TableComponent = ({ data, renderSortIcon, handleSort, handleReviewClick }) => {
  const currentUserId = parseInt(localStorage.getItem("user_id"), 10);

  return (
    <Table bordered hover className="pvp-list-table tbl-align-middle">
      <thead>
        <tr>
          <SortableHeader field="patientId" handleSort={handleSort} renderSortIcon={renderSortIcon} label="Patient ID" />
          <SortableHeader field="patientName" handleSort={handleSort} renderSortIcon={renderSortIcon} label="Patient Name" />
          <SortableHeader field="mbi" handleSort={handleSort} renderSortIcon={renderSortIcon} label="Patient MBI" />
          <SortableHeader field="dob" handleSort={handleSort} renderSortIcon={renderSortIcon} label="DOB" />
          <SortableHeader field="document.sourceReceivedDate" handleSort={handleSort} renderSortIcon={renderSortIcon} label="Document Received Date" />
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => {
          const isDisabled = item.document.reviewStatus !== "YET_TO_REVIEW" && 
          (item.document.reviewStatus !== "IN_REVIEW" || parseInt(item.document.reviewedBy, 10) !== currentUserId);
          return (
            <tr key={item.documentId}>
              <td>{item.patientId}</td>
              <td>{item.patientName}</td>
              <td>{item.mbi}</td>
              <td>{new Date(item.dob).toLocaleDateString()}</td>
              <td>{new Date(item.document.sourceReceivedDate).toLocaleDateString()}</td>
              <td>
                <Button
                  className="primary-btn"
                  onClick={() => handleReviewClick(item)}
                  disabled={isDisabled}
                >
                  {isDisabled ? <FaLock /> : "Review"}
                </Button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

const SortableHeader = ({ field, handleSort, renderSortIcon, label }) => (
  <th onClick={() => handleSort(field)} style={{ cursor: "pointer" }}>
    {label || field.charAt(0).toUpperCase() + field.slice(1)} {renderSortIcon(field)}
  </th>
);

export default TableComponent;
