import React, { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { FaHandHoldingDollar } from "react-icons/fa6";
import { FaEye, FaChartBar } from "react-icons/fa";
import { Container } from "react-bootstrap";
import Loader from "../../ui/Layout/Loader";
import usePermissions from "../../hooks/usePermissions";

const PVPHomeComponent = () => {
  const { permissions, permissionsLoaded } = usePermissions();
  console.log("permissions", permissions);
  const [clickedLinks, setClickedLinks] = useState({
    manualReview: false,
    codersList: false,
    formUpload: false,
  });

  const handleLinkClick = (link) => {
    setClickedLinks((prev) => ({ ...prev, [link]: true }));
  };

  if (!permissionsLoaded) {
    return <Loader />;
  }

  return (
    <>
      {permissions?.includes("nav_pvp") ? (
        <div>
          <Container className="mt-2 px-0">
            <h3 className="text-center">Welcome to PVP</h3>
          </Container>
          <Container className="border border-light rounded px-5">
            <Container className="container mt-4">
              <div className="row justify-content-center">
                {permissions?.includes("pvp_coder_review_list") && (
                  <div className="col-md-4 mb-5 d-flex">
                    <div className="card h-100 w-100">
                      <div className="card-body insights-card">
                        <div className="card-text text-center rounded pt-3">
                          <FaHandHoldingDollar className="fs-4 hide"></FaHandHoldingDollar>
                          <h5 className="fw-bold mt-2">Outbound to Provider</h5>
                        </div>
                        <div>
                          <p className="fs-6 p-3 text-center">
                            Coder review queue for PVP ICD codes
                          </p>
                        </div>
                      </div>
                      <div className="card-footer primary-bg text-center text-white">
                        <Link
                          to={
                            clickedLinks.codersList
                              ? "#"
                              : "/pvp/coder_review_list"
                          }
                          className={`nav-link text-white ${
                            clickedLinks.codersList ? "disabled" : ""
                          }`}
                          onClick={() => handleLinkClick("codersList")}
                        >
                          <FaEye></FaEye> View
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
                {permissions?.includes("pvp_manual_review_list") && (
                  <div className="col-md-4 mb-5 d-flex">
                    <div className="card h-100 w-100">
                      <div className="card-body insights-card">
                        <div className="card-text text-center rounded pt-3">
                          <FaChartBar className="fs-4 hide"></FaChartBar>
                          <h5 className="fw-bold mt-2">Inbound to Honest</h5>
                        </div>
                        <div>
                          <p className="fs-6 p-3 text-center">
                            Coder review queue of conditions addressed via
                            partner PDF PVP documents
                          </p>
                        </div>
                      </div>
                      <div className="card-footer primary-bg text-center text-white">
                        <Link
                          to={
                            clickedLinks.manualReview
                              ? "#"
                              : "/pvp/manual_review_list"
                          }
                          className={`nav-link text-white ${
                            clickedLinks.manualReview ? "disabled" : ""
                          }`}
                          onClick={() => handleLinkClick("manualReview")}
                        >
                          <FaEye></FaEye> View
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Container>
          </Container>
        </div>
      ) : (
        <Navigate to="/401" replace />
      )}
    </>
  );
};

export default PVPHomeComponent;
