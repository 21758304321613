import React, { useState } from "react";
import { Container, Table, Button, Form, Row, Col } from "react-bootstrap";
import { MdEdit, MdDelete } from "react-icons/md";
import { AiOutlinePlus } from "react-icons/ai";
import { Modal } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link, Navigate } from "react-router-dom";
import Loader from "../../ui/Layout/Loader";
import usePermissions from "../../hooks/usePermissions";

const ApplicationConfiguration = () => {
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const { permissions, permissionsLoaded } = usePermissions();

  if (!permissionsLoaded) {
    return <Loader />;
  }

  return (
    <>
      {permissions?.includes("administration_app_configuration") ? (
        <Container className="border border-light rounded px-5 mt-2">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link className="text-dark link" to="/administration">
                Administration
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Application Configuration</Breadcrumb.Item>
          </Breadcrumb>
          <h3>Application Configuration</h3>
          <Container className="container mt-4">
            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
              <Button className="primary-btn float-right" onClick={handleShow}>
                <AiOutlinePlus /> Add New Configuration
              </Button>
            </div>
            <div style={{ overflowY: "auto" }}>
              <Table striped bordered hover className="rounded mt-1">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Value</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>insight_cost_of_care</td>
                    <td>
                      https://tableau-qa.honestcarehub.com/#/site/QA/views/ProviderRosterDashboard_17134502487980/ProviderRosterDashboard?:iid=1
                    </td>
                    <td>
                      <Button className="btn btn-secondary">
                        <MdEdit />
                      </Button>
                      <span style={{ marginRight: "10px" }} />
                      <Button className="btn btn-danger">
                        <MdDelete />
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>insight_provider_score_card</td>
                    <td>
                      https://dashboard.honestmedicalgroup.com/#/site/DEV/views/ProviderScoreCard2_0Insights_17152743635960/Cover
                    </td>
                    <td>
                      <Button className="btn btn-secondary">
                        <MdEdit />
                      </Button>
                      <span style={{ marginRight: "10px" }} />
                      <Button className="btn btn-danger">
                        <MdDelete />
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>insight_practice_insight</td>
                    <td>
                      https://tableau-qa.honestcarehub.com/#/site/QA/views/ProviderRosterDashboard_17134502487980/ProviderRosterDashboard?:iid=1
                    </td>
                    <td>
                      <Button className="btn btn-secondary">
                        <MdEdit />
                      </Button>
                      <span style={{ marginRight: "10px" }} />
                      <Button className="btn btn-danger">
                        <MdDelete />
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>system_status</td>
                    <td>up</td>
                    <td>
                      <Button className="btn btn-secondary">
                        <MdEdit />
                      </Button>
                      <span style={{ marginRight: "10px" }} />
                      <Button className="btn btn-danger">
                        <MdDelete />
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Container>
          <Modal show={showModal} onHide={handleClose} className="modal-lg">
            <Modal.Header closeButton>
              <Modal.Title>Add New Configuration</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Row>
                  <Col xs={3}>
                    <Form.Group controlId="configName">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Name"
                        name="configName"
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={9}>
                    <Form.Group controlId="configValue">
                      <Form.Label>Value</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Value"
                        name="configValue"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-4">
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button variant="primary" type="button">
                    Submit
                  </Button>
                </div>
              </Form>
            </Modal.Body>
          </Modal>
        </Container>
      ) : (
        <Navigate to="/401" replace />
      )}
    </>
  );
};

export default ApplicationConfiguration;
