import axios from "axios";
import { API_URL } from "../config/app.constant";

export const fetchData = async (url) => {
  try {
    let token = localStorage.getItem("accessToken");
    const response = await axios.get(`${API_URL}${url}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      timeout: 10000,
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};

export const patchData = async (url, body = {}) => {
  try {
    let token = localStorage.getItem("accessToken");
    const response = await axios.patch(
      `${API_URL}${url}`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        timeout: 10000,
      }
    );
    return response;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};

export const postData = async (url, data) => {
  try {
    let token = localStorage.getItem("accessToken");
    const response = await axios.post(`${API_URL}${url}`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      timeout: 10000,
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};

export const postMultipartData = async (url, formData) => {
  try {
    let token = localStorage.getItem("accessToken");
    const response = await axios.post(`${API_URL}${url}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      timeout: 50000,
    });
    return response;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};
