import React, { useState, useEffect } from "react";
import Select from "react-select";
import { FaEye } from "react-icons/fa";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Modal,
  Button,
  Spinner,
  Alert,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { postData, fetchData } from "../../api/apiService"; 
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UserManagement = ({ roles }) => {
  const initialFormState = {
    name: "",
    email: "",
    partnerId: 0,
    practiceIds: [],
    roleId: 0,
    npi: "",
    tin: "",
  };

  const [showModalOnBoarding, setShowModalOnBoarding] = useState(false);
  const handleCloseOnBoarding = () => setShowModalOnBoarding(false);
  const handleShowOnBoarding = () => {
    setFormData(initialFormState); // Reset form data
    setShowModalOnBoarding(true);
  };

  const [formData, setFormData] = useState(initialFormState);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [partners, setPartners] = useState([]);
  const [practiceOptions, setPracticeOptions] = useState([]);

  useEffect(() => {
    // Fetch partners and practices when component mounts
    const fetchPartners = async () => {
      try {
        const response = await fetchData("/partners/partnersWithPractices");
        setPartners(response);
      } catch (error) {
        console.error("Error fetching partners:", error);
      }
    };
    fetchPartners();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (name === "partnerId") {
      const selectedPartner = partners.find(
        (partner) => partner.id === parseInt(value)
      );
      setPracticeOptions(
        selectedPartner
          ? selectedPartner.practices.map((practice) => ({
              value: practice.id,
              label: practice.name,
            }))
          : []
      );
    }
  };

  const handlePracticeChange = (selectedOptions) => {
    const practiceIds = selectedOptions.map((option) => option.value);
    setFormData({
      ...formData,
      practiceIds,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    const roleIdInt = parseInt(formData.roleId);
    const partnerIdInt = parseInt(formData.partnerId);

    // Check if roleId is a valid number
    if (isNaN(roleIdInt)) {
      console.error("Role ID must be a valid number");
      return; // Exit early if roleId is not a valid number
    }

    // Check if partnerId is a valid number
    if (isNaN(partnerIdInt)) {
      console.error("Partner ID must be a valid number");
      return; // Exit early if partnerId is not a valid number
    }

    // Update formData with roleId as an integer
    const updatedFormData = {
      ...formData,
      roleId: roleIdInt,
      partnerId: partnerIdInt,
    };

    try {
      await postData("/users", updatedFormData);
      toast.success("User Added Successfully");
      handleCloseOnBoarding();
    } catch (error) {
      console.error("Error posting data:", error);
      setError("Failed to add user.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="border border-light rounded px-5 mt-2">
      <ToastContainer />
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link className="text-dark link" to="/administration">
            Administration
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>User Management</Breadcrumb.Item>
      </Breadcrumb>
      <h3>User Management</h3>
      <Row className="justify-content-center mt-4">
        <Col md={4}>
          <Card>
            <div className="card-body insights-card">
              <div className="card-text text-center rounded pt-3">
                <h5 className="fw-bold mt-2">View Users</h5>
              </div>
              <div>
                <p className="fs-6 p-3 text-center">
                  Manage and view the details of all users in the system.
                </p>
              </div>
            </div>
            <div className="card-footer primary-bg text-center text-white">
              <Link to="/request_details" className="nav-link text-white">
                <FaEye></FaEye> View
              </Link>
            </div>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <div className="card-body insights-card">
              <div className="card-text text-center rounded pt-3">
                <h5 className="fw-bold mt-2">Add User</h5>
              </div>
              <div>
                <p className="fs-6 p-3 text-center">
                  Add a new user to the system with the required details.
                </p>
              </div>
            </div>
            <div className="card-footer primary-bg text-center text-white">
              <Link
                onClick={handleShowOnBoarding}
                className="nav-link text-white"
              >
                <FaEye></FaEye> View
              </Link>
            </div>
          </Card>
        </Col>
      </Row>
      <Modal
        className="modal-xl"
        show={showModalOnBoarding}
        onHide={handleCloseOnBoarding}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <Form.Group controlId="configName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="configValue">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="partner">
                  <Form.Label>Partner</Form.Label>
                  <Form.Select
                    name="partnerId"
                    value={formData.partnerId}
                    onChange={handleChange}
                  >
                    <option value="">Select Partner</option>
                    {partners.map((partner) => (
                      <option key={partner.id} value={partner.id}>
                        {partner.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="practice">
                  <Form.Label>Practice</Form.Label>
                  <Select
                    isMulti
                    name="practiceIds"
                    options={practiceOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={handlePracticeChange}
                    value={practiceOptions.filter((option) =>
                      formData.practiceIds.includes(option.value)
                    )}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="role">
                  <Form.Label>Role</Form.Label>
                  <Form.Select
                    name="roleId"
                    value={formData.roleId}
                    onChange={handleChange}
                  >
                    <option value="">Select Role</option>
                    {roles.map((option, index) => (
                      <option key={index} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Form.Group controlId="npi">
                  <Form.Label>NPI</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter NPI"
                    name="npi"
                    value={formData.npi}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="tin">
                  <Form.Label>TIN</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter TIN"
                    name="tin"
                    value={formData.tin}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3 justify-content-end">
              <Col xs="auto">
                <Button variant="secondary" onClick={handleCloseOnBoarding}>
                  Close
                </Button>
              </Col>
              <Col xs="auto">
                <Button
                  className=""
                  variant="primary"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Col>
              <Col xs={12}>
                {error && (
                  <Alert variant="danger" className="mt-3">
                    {error}
                  </Alert>
                )}
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default UserManagement;
