import React, { useState, useEffect } from 'react';
import { fetchData, postData } from '../../../api/apiService';
import RoleManagement from "../../../components/RoleManagement/RoleManagement";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import CSS for react-toastify

const RoleManagementPage = () => {
    const [roles, setRoles] = useState([]);
    const [components, setComponents] = useState([]);
    // const [loading, setLoading] = useState(false);
    // const [error, setError] = useState(null);

    useEffect(() => {
        const fetchRoles = async () => {
            // setLoading(true);
            try {
                const fetchedData = await fetchData('/roles');
                setRoles(fetchedData);
            } catch (error) {
                // setError(error);
            }
            // setLoading(false);
        };
        const fetchComponents = async () => {
            // setLoading(true);
            try {
                const fetchedData = await fetchData('/components');
                setComponents(fetchedData);
            } catch (error) {
                // setError(error);
            }
            // setLoading(false);
        };

        fetchRoles();
        fetchComponents();
    }, []);

    const handlePostData = async (data) => {
        data.createdBy = 1;
        data.updatedBy = 1;
        try {
            var postResult = await postData('/roles', data);
            console.log('Post Result', postResult);
            toast.success('Role created successfully!'); // Show success toast
        } catch (error) {
            toast.error('Failed to create role.'); // Show error toast
        }
    };

    return (
        <>
            <RoleManagement roles={roles} components={components} onSubmit={handlePostData} />
            <ToastContainer /> {/* Add ToastContainer to your component tree */}
        </>
    );
}

export default RoleManagementPage;
