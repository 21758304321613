import React, { useState, useEffect, useRef } from "react";
import { Container, Breadcrumb } from "react-bootstrap";
import { Navigate, Link, useNavigate } from "react-router-dom";
import Loader from "../../ui/Layout/Loader";
import usePermissions from "../../hooks/usePermissions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { io } from "socket.io-client";
import { fetchData } from "../../api/apiService";
import ManualReviewTableHeader from "./ManualReviewTableHeader";
import ManualReviewTableComponent from "./ManualReviewTableComponent";
import ManualReviewPaginationComponent from "./ManualReviewPaginationComponent";
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";

const ManualReviewListComponent = () => {
  const { permissions, permissionsLoaded } = usePermissions();
  const [data, setData] = useState([]);
  const [, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const navigate = useNavigate();
  const ws = useRef(null);

  useEffect(() => {
    ws.current = io(process.env.REACT_APP_BASE_URL);
    ws.current.on("connect", () => {
      console.log("Connected to WebSocket server");
      ws.current.emit("requestTableData");
    });
    ws.current.on("tableData", (data) => {
      console.log("Received table data from server:", data);
      setData(normalizeData(data));
    });
    ws.current.on("disconnect", () => {
      console.log("Disconnected from WebSocket server");
    });
    ws.current.on("error", (error) => {
      console.error("WebSocket error:", error);
    });
    return () => {
      ws.current.close();
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchData("/documents").then((response) => {
      setData(normalizeData(response.documents));
      setLoading(false);
    });
  }, []);

  const normalizeData = (newData) => {
    return newData.map((item) => ({
      patientId: item.patientInfo?.id || "",
      patientName: item.patientInfo?.name || "",
      mbi: item.patientInfo?.mbi || "",
      dob: item.patientInfo?.dob || "",
      documentId: item.id,
      document: {
        name: item.name,
        status: item.status,
        sourceReceivedDate: item.sourceReceivedDate,
        reviewStatus: item.reviewStatus,
        reviewedBy: item.reviewer?.id,
        reviewedAt: item.reviewer?.reviewedAt,
      },
    }));
  };

  const handleSearchChange = (e) => setSearch(e.target.value);

  const handleSort = (field) => {
    const order = sortBy === field && sortOrder === "asc" ? "desc" : "asc";
    setSortBy(field);
    setSortOrder(order);
  };

  const handleReviewClick = (item) => {
    ws.current.emit("review", {
      userId: localStorage.getItem("user_id"),
      documentId: item.documentId,
    });
    navigate(`/pvp/manual-review/${item.documentId}`);
  };

  const renderSortIcon = (field) => {
    if (sortBy === field) {
      return sortOrder === "asc" ? <FaSortUp /> : <FaSortDown />;
    }
    return <FaSort />;
  };

  const filteredData = data.filter(
    (item) =>
      String(item.patientId).includes(search) ||
      item.patientName.toLowerCase().includes(search.toLowerCase()) ||
      String(item.mbi).includes(search)
  );

  const sortedData = filteredData.sort((a, b) => {
    if (sortBy) {
      const valueA = a[sortBy];
      const valueB = b[sortBy];
      return valueA < valueB
        ? sortOrder === "asc"
          ? -1
          : 1
        : valueA > valueB
        ? sortOrder === "asc"
          ? 1
          : -1
        : 0;
    }
    return filteredData;
  });

  const currentItems = sortedData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  if (!permissionsLoaded) {
    return <Loader />;
  }

  return (
    <div>
      {permissions?.includes("pvp_manual_review_list") ? (
        <div>
          <Container className="mt-2 px-0">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link className="text-dark link" to="/pvp">
                  PVP
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Inbound to Honest</Breadcrumb.Item>
            </Breadcrumb>
          </Container>
          <Container className="border rounded px-4 pt-3 mt-2">
            <ManualReviewTableHeader
              search={search}
              handleSearchChange={handleSearchChange}
            />
            <ManualReviewTableComponent
              data={currentItems}
              renderSortIcon={renderSortIcon}
              handleSort={handleSort}
              handleReviewClick={handleReviewClick}
            />
            <ManualReviewPaginationComponent
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={filteredData.length}
              paginate={setCurrentPage}
            />
            <ToastContainer />
          </Container>
        </div>
      ) : (
        <Navigate to="/401" replace />
      )}
    </div>
  );
};

export default ManualReviewListComponent;
