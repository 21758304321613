import { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { fetchUserPermission } from "../components/SecurityComponent/SecurityComponent";
import logMessage from "../../src/utils/logger";
import { LOG_MESSAGE } from "../config/app.constant";

const usePermissions = () => {
  const [permissions, setPermissions] = useState([]);
  const [permissionsLoaded, setPermissionsLoaded] = useState(false);
  const { instance } = useMsal();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userPermission = await fetchUserPermission();
        logMessage("info", LOG_MESSAGE.LOAD_INSIGHTS, {});
        logMessage("info", LOG_MESSAGE.FETCH_PERMISSION, {
          userPermission: userPermission.dev_security_label,
        });


        const eulaVersion = userPermission.data.eulaVersion;
        const eulaAcceptedVersion = userPermission.data.user.eulaAcceptedVersion;

        if (eulaAcceptedVersion === null || eulaAcceptedVersion < eulaVersion) {
          navigate("/eula");
        }

        setPermissions(userPermission.dev_security_label);
        setPermissionsLoaded(true);
      } catch (error) {
        logMessage("info", LOG_MESSAGE.FETCH_PERMISSION, {
          error: error,
        });
        console.log("err", error)
        if (error.statusCode === 401) {
          instance.logoutRedirect({ postLogoutRedirectUri: "/" });
        }

        if (error.statusCode === 403) {
          navigate("/403");
        }
      }
    };

    fetchData();
  }, [instance, navigate]);

  return { permissions, permissionsLoaded };
};

export default usePermissions;
