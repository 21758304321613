import React, { useState, useEffect } from "react";
import { fetchData } from "../../../api/apiService";
import RequestDetails from "../../../components/RequestDetails/RequestDetails";

const RequestDetailsPage = () => {
  const [roles, setRoles] = useState([]);
  useEffect(() => {
    const fetchRoles = async () => {
      // setLoading(true);
      try {
        const fetchedData = await fetchData("/roles");
        setRoles(fetchedData);
      } catch (error) {
        // setError(error);
      }
      // setLoading(false);
    };

    fetchRoles();
  }, []);
  return <RequestDetails roles={roles}></RequestDetails>;
};

export default RequestDetailsPage;
