import React from "react";
import { Row, Col, Container } from "react-bootstrap";

const CoderReviewPatientInfo = ({ formValues }) => (
  <Container className="pvp-coder-ui-patient-container">
    <Row className="pvp-form-row mb-3">
      <Col md={3}>
        <label className="pvp-form-label-padding">Patient Name</label>
        <input
          type="text"
          className="pvp-form-control manual-review-input"
          name="patientName"
          value={formValues.patientName}
          readOnly
        />
      </Col>
      <Col md={3}>
        <label className="pvp-form-label-padding">Patient ID</label>
        <input
          type="text"
          className="pvp-form-control manual-review-input"
          name="patientId"
          value={formValues.patientId}
          readOnly
        />
      </Col>
      <Col md={3}>
        <label className="pvp-form-label-padding">Patient DOB</label>
        <input
          type="text"
          className="pvp-form-control manual-review-input"
          name="dob"
          value={formValues.dob}
          readOnly
        />
      </Col>
      <Col md={3}>
        <label className="pvp-form-label-padding">Patient MBI</label>
        <input
          type="text"
          className="pvp-form-control manual-review-input"
          name="mbi"
          value={formValues.mbi}
          readOnly
        />
      </Col>
    </Row>
    <Row className="pvp-form-row mb-3">
      <Col md={3}>
        <label className="pvp-form-label-padding">Gender</label>
        <input
          type="text"
          className="pvp-form-control manual-review-input"
          name="gender"
          value={formValues.gender}
          readOnly
        />
      </Col>
      <Col md={3}>
        <label className="pvp-form-label-padding">Provider Name</label>
        <input
          type="text"
          className="pvp-form-control manual-review-input"
          name="providerName"
          value={formValues.providerName}
          readOnly
        />
      </Col>
      <Col md={3}>
        <label className="pvp-form-label-padding">Office/IPA</label>
        <input
          type="text"
          className="pvp-form-control manual-review-input"
          name="office"
          value={formValues.office}
          readOnly
        />
      </Col>
      <Col md={3}>
        <label className="pvp-form-label-padding">Date of Service</label>
        <input
          type="text"
          className="pvp-form-control manual-review-input"
          name="dateOfService"
          value={formValues.dateOfService}
          readOnly
        />
      </Col>
    </Row>
  </Container>
);

export default CoderReviewPatientInfo;
